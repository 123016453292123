import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/tickets.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { FreshdeskService } from '../../services';

export const LoadTickets$ = createEffect(
  (actions$ = inject(Actions), service = inject(FreshdeskService)) => {
    return actions$.pipe(
      ofType(actions.LoadTickets),
      switchMap(({}) =>
        service.getTickets().pipe(
          map(tickets => actions.LoadTicketsSuccess({ tickets })),
          catchError(error => of(actions.LoadTicketsFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

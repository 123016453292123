import { createReducer, on } from '@ngrx/store';
import * as UserActions from '../actions/search-results.action';
import { ToEntities } from '@teamfoster/sdk/array-utility';
import { Profile } from 'src/app/auth/models';
import { SearchResult } from '../../models';

export interface SearchResultsState {
  results: SearchResult[];
  query: string;
  loading: boolean;
  loaded: boolean;
  error?: any;
}

export const initialState: SearchResultsState = {
  results: [],
  query: '',
  loaded: false,
  loading: false,
};

export const SearchResultsReducer = createReducer(
  initialState,
  on(UserActions.LoadSearchResults, (state: SearchResultsState, { query }) => {
    return {
      ...state,
      query,
      loading: true,
    };
  }),
  on(UserActions.LoadSearchResultsSuccess, (state: SearchResultsState, { results }) => {
    return {
      ...state,
      results,
      loading: false,
      loaded: true,
    };
  }),
  on(UserActions.LoadSearchResultsFail, (state: SearchResultsState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getSearchResults = (state: SearchResultsState) => state.results;
export const getSearchQuery = (state: SearchResultsState) => state.query;
export const getSearchResultsLoading = (state: SearchResultsState) => state.loading;
export const getSearchResultsLoaded = (state: SearchResultsState) => state.loaded;

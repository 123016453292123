import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@teamfoster/sdk/icon';
import { LinkComponent } from '@teamfoster/sdk/button';
import { NavLink } from '../../models';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-bottom-bar',
  standalone: true,
  imports: [CommonModule, IconComponent, LinkComponent, MatIconModule, MatMenuModule, RouterModule],
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomBarComponent {
  menu = input.required<NavLink[]>();

  openTabCount = input<number>();
  openTabs = output<void>();

  mobileMenu$ = computed(() => this.menu().filter(a => a.mobile));
  overflowMenu$ = computed(() => this.menu().filter(a => !a.mobile));
}

import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { SearchResult } from '../../models';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-search-result',
  standalone: true,
  imports: [],
  templateUrl: './search-result.component.html',
  styleUrl: './search-result.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultComponent {
  result = input.required<SearchResult>();
}

import { Component, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-404',
  styles: ':host{display: contents}',
  template: `
    <article class="section-inset-y w-100 d-flex align-items-center">
      <div class="container text-center section-inset-b">
        <h1>Page not found</h1>
        <p class="text-max-width mx-auto">Check the addres and try again</p>
        <div class="error-404__content text-center mt-4">
          <a [routerLink]="'/'" class="button button--primary">Terug naar home</a>
        </div>
      </div>
    </article>
  `,
  standalone: true,
  imports: [RouterLink],
})
export class NotFoundComponent {
  private router = inject(Router);
  code = 404;

  constructor() {
    const statusCodeFromUrl = parseInt(this.router.url.replace(/\//g, ''));
    this.code = statusCodeFromUrl ? statusCodeFromUrl : 404;
  }
}

import { enableProdMode, LOCALE_ID, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { provideQuillConfig } from 'ngx-quill';
import { DynamicFormModule, DynamicFormConfig } from '@teamfoster/dynamic-forms';
import { ProvideIcons } from '@teamfoster/sdk/icon';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideRouterStore } from '@ngrx/router-store';
import { provideEffects } from '@ngrx/effects';
import { reducers, appEffects, CustomSerializer } from './app/store';
import { provideState, provideStore } from '@ngrx/store';
import { provideAnimations } from '@angular/platform-browser/animations';
import { appRoutes } from './app.routes';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { bootstrapApplication } from '@angular/platform-browser';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import * as fromAuth from './app/auth/store';
import * as fromBacklog from './app/backlog-items/store';
import * as fromSprints from './app/sprints/store';
import * as fromProjects from './app/projects/store';
import * as fromCommandPalette from './app/command-palette/store';
import * as fromFreshdesk from './app/freshdesk/store';
import { registerLocaleData } from '@angular/common';

// Locale
import localeNl from './app/nl';
import Quill from 'quill';
import QuillCursors from 'quill-cursors';
import { ImageSharpConfig, ProvideOptimisedImageProcessor } from '@teamfoster/sdk/image';

import 'canvas-confetti';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';

const dynamicFormConfig: DynamicFormConfig = {
  genericInputErrorMessage: 'Het formulier is niet correct ingevuld, controleer de velden en probeer nogmaals.',
  genericServerErrorMessage:
    'Er is iets fout gegaan bij het versturen van het formulier, probeer het later nogmaals. Blijft het probleem voordoen neem dan contact met ons op.',
};

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeNl, 'nl');
Quill.register('modules/cursors', QuillCursors);

const globalRippleConfig: RippleGlobalOptions = {
  disabled: false,
  //animation: {
  //  enterDuration: 50000,
  //  exitDuration: 100,
  //},
};

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(appRoutes, withComponentInputBinding()),
    provideStore(reducers),
    provideState(fromAuth.featureKey, fromAuth.reducers),
    provideState(fromBacklog.featureKey, fromBacklog.reducers),
    provideState(fromSprints.featureKey, fromSprints.reducers),
    provideState(fromProjects.featureKey, fromProjects.reducers),
    provideState(fromCommandPalette.featureKey, fromCommandPalette.reducers),
    provideState(fromFreshdesk.featureKey, fromFreshdesk.reducers),
    provideEffects({
      ...appEffects,
      ...fromAuth.effects,
      ...fromBacklog.effects,
      ...fromSprints.effects,
      ...fromProjects.effects,
      ...fromCommandPalette.effects,
      ...fromFreshdesk.effects,
    }),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: environment.production,
      trace: !environment.production,
    }),
    provideRouterStore({ serializer: CustomSerializer }),
    provideQuillConfig({}),
    importProvidersFrom(
      // Foster Modules
      DynamicFormModule.forRoot(dynamicFormConfig)
    ),
    ProvideIcons({ spritesheetUrl: 'assets/dist/svg/symbol/svg/sprite.symbol.svg' }),
    ProvideOptimisedImageProcessor(ImageSharpConfig, {}),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    { provide: LOCALE_ID, useValue: 'nl' },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
  ],
});

<div class="app-container">
  <section class="app" [class.anim--in]="loading$()">
    <!--<aside class="app__topbar">
      @if (loaded$()) {
        <app-topbar
          (toggleOverlay)="toggleMenu()"
          (closeOverlay)="closeMenu()"
          (openOverlay)="openMenu()"
          [menuActive]="menuOpen$()"
          [user]="user$()"
        ></app-topbar>
      }
    </aside>-->

    @if (user$()) {
      <aside class="app__navbar d-none d-md-block">
        <app-side-nav
          (triggerSearch)="commandPalette(null)"
          [user]="user$()"
          [menu]="navItems()"
          [subMenu]="subNavItems"
          [theme]="theme$()"
          [user]="user$()"
        ></app-side-nav>
      </aside>
    }

    <main class="app__main">
      <router-outlet #outlet="outlet" class="router" [@routerFade]="prepareRoute(outlet)"></router-outlet>
    </main>

    <app-backlog-item-bar class="app__aside" (openBacklogItemsCount)="openBacklogItemCount.set($event)"></app-backlog-item-bar>
  </section>

  @if (user$()) {
    <section class="d-md-none">
      <app-bottom-bar [menu]="allNavItems()" [openTabCount]="openBacklogItemCount()"></app-bottom-bar>
    </section>
  }
  @if (testGridEnabled) {
    <app-grid-overlay></app-grid-overlay>
  }
  <app-loader-indicator [loading]="loading$()"></app-loader-indicator>
</div>

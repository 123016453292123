import { Routes } from '@angular/router';
import { NotFoundComponent } from './app/404.component';
import { AuthGuard } from './app/auth/guards/auth.guard';
import { RequirementPresetGuard } from './app/backlog-items';
import { OpenBacklogItemGuard } from './app/backlog-items/guards/open-item.guard';
import { FreshdeskTicketsGuard } from './app/freshdesk/guards';

const appGuards = [AuthGuard, OpenBacklogItemGuard, FreshdeskTicketsGuard];

export const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/sprints',
    data: {
      pageTitle: 'Dashboard',
    },
  },
  {
    path: 'auth',
    loadChildren: () => import('./app/auth/auth.routes').then(m => m.routes),
    data: {
      pageTitle: 'Log in',
    },
  },
  {
    path: 'backlog',
    loadChildren: () => import('./app/backlog-items/backlog.routes').then(m => m.routes),
    canActivate: [...appGuards, RequirementPresetGuard],
    data: {
      pageTitle: 'Backlog',
    },
  },
  {
    path: 'analytics',
    loadChildren: () => import('./app/analytics/analytics.routes').then(m => m.routes),
    canActivate: [...appGuards],
    data: {
      pageTitle: 'Analytics',
    },
  },
  {
    path: 'sprints',
    loadChildren: () => import('./app/sprints/sprints.routes').then(m => m.routes),
    canActivate: [...appGuards, RequirementPresetGuard],
    data: {
      pageTitle: 'Sprints',
    },
  },
  {
    path: 'projects',
    loadChildren: () => import('./app/projects/projects.routes').then(m => m.routes),
    canActivate: [...appGuards, RequirementPresetGuard],
    data: {
      pageTitle: 'Projects',
    },
  },
  {
    path: 'settings',
    loadChildren: () => import('./app/settings/settings.routes').then(m => m.routes),
    canActivate: [...appGuards, RequirementPresetGuard],
    data: {
      pageTitle: 'Settings',
    },
  },

  {
    path: 'form-controls',
    loadChildren: () => import('./app/form-controls/form-controls.routes').then(m => m.routes),
    canActivate: [],
    data: {
      pageTitle: 'Styleguide',
    },
  },
  {
    path: 'styleguide',
    loadChildren: () => import('./app/styleguide/styleguide.routes').then(m => m.routes),
    canActivate: [],
    data: {
      pageTitle: 'Styleguide',
    },
  },
  {
    path: 'presentation',
    loadChildren: () => import('./app/presentation/presentation.routes').then(m => m.routes),
    canActivate: [],
    data: {
      pageTitle: 'presentation',
    },
  },
  {
    path: 'tools',
    loadChildren: () => import('./app/tools/tools.routes').then(m => m.routes),
    canActivate: [...appGuards],
    data: {
      pageTitle: 'Tools',
    },
  },
  {
    path: '404',
    component: NotFoundComponent,
    canActivate: [...appGuards],
    data: {
      pageTitle: 'Pagina niet gevonden',
    },
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

<div class="d-flex align-items-center gap-3">
  <div matListItemIcon class="status" [ngClass]="'status--' + (result().status | statusLabel)">
    <span class="visually-hidden">{{ result().status | statusLabel }}</span>
  </div>
  <span
    class="chip chip--project"
    [title]="result().tag"
    [style.--project-color]="result().tagColor"
    [style.--project-text-color]="result().tagColor | colorContrast"
  >
    <span>{{ result().tag }}</span>
  </span>
  <div class="d-flex align-items-start">
    <span class="chip chip--primary flex-shrink-0 me-2 d-inline-block">
      {{ result().subTitle }}
    </span>
    <span class="flex-grow-1 fw-semi text-truncate">
      {{ result().title }}
    </span>
    <!--<span class="chip chip--primary chip--xs">{{ result().subTitle }}</span>-->
  </div>
</div>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SearchResultComponent } from '../search-result/search-result.component';
import { NgClass, SlicePipe } from '@angular/common';
import { StatusLabelPipe } from '../../../backlog-items/pipes/status-label.pipe';
import { ColorContrastPipe } from '../../../backlog-items/pipes/color-contrast.pipe';

@Component({
  selector: 'app-search-result-backlog-item',
  standalone: true,
  imports: [NgClass, StatusLabelPipe, ColorContrastPipe, SlicePipe],
  templateUrl: './search-result-backlog-item.component.html',
  styleUrl: './search-result-backlog-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultBacklogItemComponent extends SearchResultComponent {}

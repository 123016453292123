import * as fromTickets from './tickets.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'Freshdesk';

export interface FreshdeskState {
  tickets: fromTickets.TicketsState;
}

export const reducers: ActionReducerMap<FreshdeskState> = {
  tickets: fromTickets.TicketsReducer,
};

export const getFreshdeskState = createFeatureSelector<FreshdeskState>(featureKey);
export const metaReducers: MetaReducer<FreshdeskState>[] = !environment.production ? [] : [];

<div class="d-flex align-items-center gap-3">
  <mat-icon class="text-primary">emoji_objects</mat-icon>

  <span
    class="chip chip--project flex-shrink-0 w-auto"
    [title]="result().tag"
    [style.--project-color]="result().tagColor"
    [style.--project-text-color]="result().tagColor | colorContrast"
  >
    <span>{{ result().tag | slice: 0 : 6 }}</span>
  </span>

  <span class="flex-grow-1">
    {{ result().title }}
  </span>
</div>

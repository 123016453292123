import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/tickets.action';
import { ToEntities } from '@teamfoster/sdk/array-utility';
import { Profile } from 'src/app/auth/models';

export interface TicketsState {
  entities: { [id: number]: any };
  count: number;
  loading: boolean;
  loaded: boolean;
  error?: any;
}

export const initialState: TicketsState = {
  entities: {},
  count: 0,
  loaded: false,
  loading: false,
};

export const TicketsReducer = createReducer(
  initialState,
  on(actions.LoadTickets, (state: TicketsState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(actions.LoadTicketsSuccess, (state: TicketsState, { tickets }) => {
    const entities = ToEntities(tickets.tickets, 'id', state.entities);

    return {
      ...state,
      entities,
      count: tickets.count,
      loading: false,
      loaded: true,
    };
  }),
  on(actions.LoadTicketsFail, (state: TicketsState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getTicketsEntities = (state: TicketsState) => state.entities;
export const getTicketsCount = (state: TicketsState) => state.count;
export const getTicketsLoading = (state: TicketsState) => state.loading;
export const getTicketsLoaded = (state: TicketsState) => state.loaded;

import { inject } from '@angular/core';

import { Store } from '@ngrx/store';

import * as fromStore from '../store';
import { of } from 'rxjs';
import { ActivatedRoute, CanActivateFn, Router } from '@angular/router';

export const OpenBacklogItemGuard: CanActivateFn = route => {
  const store = inject(Store);
  const router = inject(Router);
  const activatedRoute = inject(ActivatedRoute);
  const bliId = +route.queryParams['backlogItemId'];

  if (bliId > 0) {
    let openItems: number[] = JSON.parse(localStorage.getItem('afterburner:open-items') || '[]');
    let activeItem = bliId;

    const activeIndex = openItems.indexOf(+activeItem);
    if (activeIndex > -1) {
      openItems[activeIndex] = bliId;
    } else {
      openItems = [...openItems, bliId];
    }

    localStorage.setItem('afterburner:active-item', `${bliId}`);
    localStorage.setItem('afterburner:open-items', JSON.stringify([...openItems]));
  }

  return true;
};

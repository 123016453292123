import { Component, input } from '@angular/core';

@Component({
  selector: 'app-loader-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
  standalone: true,
})
export class LoaderIndicatorComponent {
  loading = input<boolean>(false);
}

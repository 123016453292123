import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/search-results.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { SearchResultsService } from '../../services';

export const LoadSearchResults$ = createEffect(
  (actions$ = inject(Actions), service = inject(SearchResultsService)) => {
    return actions$.pipe(
      ofType(actions.LoadSearchResults),
      switchMap(({ query }) =>
        service.getSearchResults(query).pipe(
          map(results => actions.LoadSearchResultsSuccess({ results })),
          catchError(error => of(actions.LoadSearchResultsFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

import * as fromSearchResults from './search-results.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'CommandPalette';

export interface CommandPaletteState {
  searchResults: fromSearchResults.SearchResultsState;
}

export const reducers: ActionReducerMap<CommandPaletteState> = {
  searchResults: fromSearchResults.SearchResultsReducer,
};

export const getCommandPaletteState = createFeatureSelector<CommandPaletteState>(featureKey);
export const metaReducers: MetaReducer<CommandPaletteState>[] = !environment.production ? [] : [];

import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromTickets from '../reducers/tickets.reducer';

export const getTicketsState = createSelector(fromFeature.getFreshdeskState, (state: fromFeature.FreshdeskState) => state.tickets);

export const getTicketsEntities = createSelector(getTicketsState, fromTickets.getTicketsEntities);
export const getTicketsCount = createSelector(getTicketsState, fromTickets.getTicketsCount);
export const getTicketsLoaded = createSelector(getTicketsState, fromTickets.getTicketsLoaded);
export const getTicketsLoading = createSelector(getTicketsState, fromTickets.getTicketsLoading);

export const getAllTickets = createSelector(getTicketsEntities, entities => {
  return Object.keys(entities).map(id => entities[+id]);
});

import { inject } from '@angular/core';

import { Store } from '@ngrx/store';
import { tap, filter, take, map } from 'rxjs/operators';

import { CanActivateFn } from '@angular/router';
import { getTicketsLoaded, LoadTickets } from '../store';

export const FreshdeskTicketsGuard: CanActivateFn = route => {
  const store = inject(Store);

  return store.select(getTicketsLoaded).pipe(
    tap(loaded => {
      if (!loaded) {
        store.dispatch(LoadTickets());
      }
    }),
    filter(loaded => loaded),
    take(1),
    map(loaded => loaded)
  );
};

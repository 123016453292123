import { Component, Renderer2, inject, computed, signal, effect, HostListener } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { DOCUMENT, AsyncPipe } from '@angular/common';
import { Router, NavigationEnd, RouterOutlet, NavigationStart, NavigationCancel } from '@angular/router';
import { Store } from '@ngrx/store';

import * as navActions from './store/actions/nav.action';
import * as navSelectors from './store/selectors/nav.selector';
import { filter } from 'rxjs/operators';
import { routerFade } from './animations';
import { ChangeDetectionStrategy } from '@angular/core';
import { getRouterState } from './store';
import { MatIconRegistry } from '@angular/material/icon';
import { getAuthUser } from './auth/store';
import { LoaderIndicatorComponent } from './core/components/loading-indicator/loading-indicator.component';
import { GridOverlayComponent } from './core/components/grid-overlay/grid-overlay.component';
import { BacklogItemBarComponent } from './backlog-items/components/backlog-item-bar/backlog-item-bar.component';
import { SideNavComponent } from './navigation/components/side-nav/side-nav.component';
import { TopbarComponent } from './navigation/components/topbar/topbar.component';
import { NavLink } from './navigation/models';
import { BottomBarComponent } from './navigation/components/bottom-bar/bottom-bar.component';
import { THEME_LOCALSTORAGE_KEY } from './store/effects/theme.effect';
import { SetTheme } from './store/actions/theme.action';
import { CommandPaletteComponent } from './command-palette';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { firstValueFrom } from 'rxjs';
import { getTheme } from './store/selectors/theme.selector';
import { getTicketsCount } from './freshdesk';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  animations: [routerFade],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TopbarComponent,
    SideNavComponent,
    RouterOutlet,
    BacklogItemBarComponent,
    GridOverlayComponent,
    LoaderIndicatorComponent,
    AsyncPipe,
    BottomBarComponent,
  ],
})
export class AppComponent {
  private store = inject(Store);
  private router = inject(Router);
  private renderer = inject(Renderer2);
  private dlg = inject(Dialog);
  private document: any = inject(DOCUMENT);

  private _commandPalette?: DialogRef<CommandPaletteComponent, CommandPaletteComponent>;

  @HostListener('window:keydown.Control.k', ['$event'])
  @HostListener('window:keydown.Meta.k', ['$event'])
  async commandPalette(event: KeyboardEvent | null) {
    event?.preventDefault();
    if (this._commandPalette) {
      this._commandPalette.close();
      this._commandPalette = undefined;
      return;
    }
    this._commandPalette = this.dlg.open(CommandPaletteComponent, { minWidth: '40vw', maxWidth: '700px', width: '100%' });
    await firstValueFrom(this._commandPalette.closed);
    this._commandPalette = undefined;
  }

  domainName: string = this.document.location.hostname;
  testGridEnabled = false;
  routerAnimationState = '';

  openBacklogItemCount = signal<number>(0);

  subNavItems: NavLink[] = [
    {
      routerLink: ['/', 'settings'],
      url: '',
      text: 'Settings',
      internalUsersOnly: true,
      icon: 'settings',
    },
  ];

  routeState$ = this.store.selectSignal(getRouterState);
  menuOpen$ = this.store.selectSignal(navSelectors.getMenuOpen);
  freshdeskTicketCount$ = this.store.selectSignal(getTicketsCount);

  navItems = computed(() => {
    const ticketCount = this.freshdeskTicketCount$();
    return [
      {
        routerLink: ['/', 'sprints'],
        url: '',
        text: 'Current sprint',
        shortText: 'Current',
        internalUsersOnly: true,
        icon: 'view_kanban',
        mobile: true,
      },
      {
        routerLink: ['/', 'backlog'],
        url: '',
        text: 'Backlog',
        internalUsersOnly: true,
        icon: 'list_alt',
        mobile: true,
      },
      {
        routerLink: ['/', 'projects'],
        url: '',
        text: 'Projects',
        internalUsersOnly: false,
        icon: 'cases',
        mobile: true,
      },
      {
        routerLink: ['/', 'tools'],
        url: '',
        text: 'Tools',
        internalUsersOnly: true,
        icon: 'ifl',
        mobile: true,
      },
      {
        url: 'https://foster.freshdesk.com/a/tickets/filters/search?orderBy=created_at&orderType=desc&q[]=status%3Fis_in%3A%5B2%2C3%2C0%5D&ref=all_tickets',
        text: 'Freshdesk',
        internalUsersOnly: true,
        icon: 'bug_report',
        badge: ticketCount > 0 ? ticketCount : undefined,
      },
      {
        routerLink: ['/', 'analytics'],
        url: '',
        text: 'Analytics',
        internalUsersOnly: true,
        icon: 'monitoring',
      },
    ];
  });

  allNavItems = computed(() => [...this.navItems(), ...this.subNavItems]);

  private routerEvents$ = toSignal(
    this.router.events.pipe(filter(e => [NavigationStart, NavigationEnd, NavigationCancel].some(b => e instanceof b)))
  );
  loading$ = computed(() => {
    return !(this.routerEvents$() instanceof NavigationEnd) && !(this.routerEvents$() instanceof NavigationCancel);
  });
  loaded$ = signal<boolean>(true);

  user$ = this.store.selectSignal(getAuthUser);
  theme$ = this.store.selectSignal(getTheme);

  menuOpenEffect$ = effect(() => this.toggleMenuClass(this.menuOpen$()));
  routerEffect$ = effect(() => {
    const event = this.routerEvents$();
    const state = this.routeState$();

    this.document.querySelector('body').classList.add('set--in');
    this.document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  });

  constructor(matIconRegistry: MatIconRegistry) {
    matIconRegistry.setDefaultFontSetClass('material-symbols-rounded');

    const theme = localStorage.getItem(THEME_LOCALSTORAGE_KEY);
    if (theme) {
      this.store.dispatch(SetTheme({ theme }));
    }
  }

  toggleMenu() {
    this.store.dispatch(navActions.ToggleMenu());
  }

  closeMenu() {
    this.store.dispatch(navActions.CloseMenu());
  }

  openMenu() {
    this.store.dispatch(navActions.OpenMenu());
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData;
  }

  private toggleMenuClass(menuActive: boolean) {
    menuActive
      ? this.renderer.addClass(this.document.body, 'main-nav--active')
      : this.renderer.removeClass(this.document.body, 'main-nav--active');
  }
}

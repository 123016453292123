<nav class="bottom-bar p-2">
  <ul class="d-flex align-items-center gap-1 justify-content-around">
    @for (item of mobileMenu$(); track item.text) {
      <li class="">
        <fstr-link
          [cssClasses]="'tab-button'"
          [button]="{ text: item.text, url: item.url ? item.url : '', routerLink: item.routerLink }"
          [showText]="false"
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLinkActive]="'is--active'"
        >
          <mat-icon>{{ item.icon }}</mat-icon>
          <span class="">{{ item.shortText || item.text }}</span>
        </fstr-link>
      </li>
    }

    <!--@if (openTabCount()) {
        <li class="ms-auto">
          <span class="open-tabs">
            {{ openTabCount() }}
          </span>
        </li>
      }-->

    <li>
      <button class="button button-link button--primary button--md" [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </li>
  </ul>

  <mat-menu #menu="matMenu">
    @for (item of overflowMenu$(); track item.text) {
      <a mat-menu-item [routerLink]="item.routerLink">
        <mat-icon>{{ item.icon }}</mat-icon>
        <span>{{ item.text }}</span>
      </a>
    }
  </mat-menu>
</nav>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SearchResultComponent } from '../search-result/search-result.component';
import { MatIconModule } from '@angular/material/icon';
import { ColorContrastPipe } from '../../../backlog-items/pipes/color-contrast.pipe';
import { SlicePipe } from '@angular/common';

@Component({
  selector: 'app-search-result-project',
  standalone: true,
  imports: [MatIconModule, ColorContrastPipe, SlicePipe],
  templateUrl: './search-result-project.component.html',
  styleUrl: './search-result-project.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultProjectComponent extends SearchResultComponent {}

<nav class="side-nav px-2">
  <a class="link-reset d-block px-2 pt-3 pb-6" [routerLink]="['/sprints']">
    <img class="logo" src="/assets/gfx/logo-afterburner.svg" alt="Afterburner" />
  </a>

  @if (user(); as user) {
    <ul class="side-nav-list d-flex flex-column gap-1">
      <!--  [matTooltipHideDelay]="500000" -->
      <li class="side-nav-list__item mb-4" [matTooltip]="'Search'" [matTooltipPosition]="'right'" [matTooltipClass]="'tooltip-primary'">
        <button class="tab-button tab-button--accent" (click)="triggerSearch.emit()">
          <mat-icon class="tab-button__icon">search</mat-icon>
        </button>
      </li>

      @for (item of allMenuItems(); track item; let i = $index) {
        <li
          class="side-nav-list__item"
          [matTooltip]="item.text"
          [matTooltipPosition]="'right'"
          [class.mt-auto]="i === menu().length"
          [matTooltipClass]="'tooltip-primary'"
        >
          @if (item.internalUsersOnly ? user.roles.indexOf('external') < 0 : true) {
            <fstr-link
              [cssClasses]="'tab-button ' + (i > menu().length - 1 ? 'tab-button--small' : '')"
              [button]="item"
              [showText]="false"
              routerLinkActive="is--active"
            >
              <mat-icon class="tab-button__icon" [matBadge]="item.badge" matBadgeColor="warn">
                {{ item.icon }}
              </mat-icon>
              <!--<span class="tab-button__label">{{ item.text }}</span>-->
            </fstr-link>
          }
        </li>
      }

      @if (theme(); as theme) {
        <li
          class="side-nav-list__item"
          [matTooltip]="'Enable ' + (theme === 'dark' ? 'light' : 'dark') + ' theme'"
          [matTooltipPosition]="'right'"
          [matTooltipClass]="'tooltip-primary'"
        >
          <button class="tab-button tab-button--small" (click)="setTheme(theme)">
            <mat-icon class="tab-button__icon">{{ theme === 'dark' ? 'light' : 'dark' }}_mode</mat-icon>
          </button>
        </li>
      }
      <li class="side-nav-list__item" [matTooltip]="'Sign out'" [matTooltipPosition]="'right'" [matTooltipClass]="'tooltip-primary'">
        <button class="tab-button tab-button--small" (click)="signOut()">
          <mat-icon class="tab-button__icon">logout</mat-icon>
          <!--<span class="tab-button__label">Sign out</span>-->
        </button>
      </li>
      <li>
        <div class="user-thumb mt-2 mb-3">
          <img class="w-100" [src]="user.avatar" [alt]="user.firstName" />
        </div>
      </li>
    </ul>
  }
</nav>

import { Component, computed, inject, input, output } from '@angular/core';
import { Profile } from 'src/app/auth/models';
import { LinkComponent } from '@teamfoster/sdk/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { NavLink } from '../../models';
import { Store } from '@ngrx/store';
import * as fromAuth from '../../../auth/store';
import { MatTooltip } from '@angular/material/tooltip';
import { SetTheme } from 'src/app/store/actions/theme.action';
import { MatBadgeModule } from '@angular/material/badge';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrl: './side-nav.component.scss',
  standalone: true,
  imports: [LinkComponent, RouterLinkActive, MatIconModule, RouterLink, MatTooltip, MatBadgeModule],
})
export class SideNavComponent {
  user = input<Profile>();
  menu = input<NavLink[]>([]);
  subMenu = input<NavLink[]>([]);
  theme = input<string>();

  triggerSearch = output<void>();

  private store = inject(Store);

  allMenuItems = computed(() => {
    return [...this.menu(), ...this.subMenu()];
  });

  signOut() {
    this.store.dispatch(fromAuth.SignOut());
  }
  setTheme(currentTheme: string) {
    this.store.dispatch(SetTheme({ theme: currentTheme === 'dark' ? 'light' : 'dark' }));
  }
}

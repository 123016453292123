import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromSearchResults from '../reducers/search-results.reducer';
import { SearchResult } from '../../models';

export const getSearchResultsState = createSelector(
  fromFeature.getCommandPaletteState,
  (state: fromFeature.CommandPaletteState) => state.searchResults
);

export const getSearchResults = createSelector(getSearchResultsState, fromSearchResults.getSearchResults);
export const getSearchQuery = createSelector(getSearchResultsState, fromSearchResults.getSearchQuery);
export const getSearchResultsLoaded = createSelector(getSearchResultsState, fromSearchResults.getSearchResultsLoaded);
export const getSearchResultsLoading = createSelector(getSearchResultsState, fromSearchResults.getSearchResultsLoading);

export const getGroupedSearchResults = createSelector(getSearchResults, results => {
  const sortOrder = ['BacklogItem', 'Project', 'Sprint'];
  return results
    .reduce((acc: { type: string; results: SearchResult[] }[], result) => {
      let index = acc.findIndex((item: any) => item.type === result.type);

      if (index === -1) {
        acc = [...acc, { type: result.type, results: [result] }];
        return acc;
      }
      acc[index].results = [...acc[index].results, result];
      // sort results by orderBy (Date field)
      acc[index].results = acc[index].results.sort((a, b) => {
        if (a.orderBy && b.orderBy) {
          return new Date(b.orderBy).getTime() - new Date(a.orderBy).getTime();
        }
        return 0;
      });
      return acc;
    }, [])
    .sort((a, b) => sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type));
});
